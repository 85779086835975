import React, { useState, useRef } from 'react';
import axios from 'axios';
import './UploadData.css';

function UploadData() {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const fileInputRef = useRef(null);

  // Handle click to select file
  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];

    if (validateFile(selectedFile)) {
      setFile(selectedFile);
    }
  };

  // Handle file drop
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];

    if (validateFile(droppedFile)) {
      setFile(droppedFile);
    }
  };

  // Validate either .zip or .json
  const validateFile = (file) => {
    const validExtensions = ['application/zip', 'application/json'];
    if (!validExtensions.includes(file.type)) {
      setErrorMessage('Please upload a valid .zip or .json file.');
      return false;
    }
    return true;
  };

  // Prevent default behavior for drag over events
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Trigger file input when dropzone clicked
  const handleDropzoneClick = () => {
    fileInputRef.current.click();
  }

  const uploadFile = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    // Log formData entries
    for (let pair of formData.entries()) {
      console.log(`formData. J: ${pair[0]}:`, pair[1]);
    }

    const token = localStorage.getItem('token');
    console.log('Token being sent:', token);

    try {
      const response = await axios.post('https://spoti-pals-1950205a25ce.herokuapp.com/api/upload', formData, {
      });
      console.log('Server response. J:', response.data.message);
    } catch (error) {
      console.error('Error uploading file: J', error);
      if (error.response) {
        console.error('Response status:', error.response.status);
        console.error('Response data:', error.response.data);
      }
      setErrorMessage('Error uploading file. Please try again. J' );
    }
  };

  return (
    <div className="upload-container">
      <div
        className="dropzone"
        onClick={handleDropzoneClick}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <p>Drag and drop a .zip or .json file here, or click to select a file</p>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileSelect}
          accept=".zip,.json"
          className="file-input"
        />
      </div>

      {errorMessage && <p className="error-message">{errorMessage}</p>}

      {file && (
        <div className="file-details">
          <p><strong>Selected file:</strong> {file.name}</p>
          <p><strong>File size:</strong> {(file.size / (1024 * 1024)).toFixed(2)} MB</p>
          <button onClick={uploadFile}>Upload File</button>
        </div>
      )}
    </div>
  );
};

export default UploadData;
